import React from 'react'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

import Bio from '../components/organisms/Bio'
import Header from '../components/organisms/Header'
import PostsList from '../components/molecules/PostsList'
import MainContainer from '../components/atoms/MainContainer'

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <MainContainer>
      <GatsbySeo title='All posts' />
      <Header />
      <Bio />
      <PostsList posts={posts} />
    </MainContainer>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`

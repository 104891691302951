import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import MainContainer from '../../atoms/MainContainer'

const SiteTitle = styled.h1`
  & > a {
    color: ${({ theme }) => get(theme, 'black')};
    font-size: 48px;
    font-weight: 900;
    text-decoration: none;
    cursor: pointer;
  }

  margin-top: 48px;
  margin-bottom: 48px;
`

const ArticleTitle = styled.header `
  & > a {
    color: ${({ theme }) => get(theme, 'titleColor')};
    font-size: 19px;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
  }

  margin-top: 40px;
  margin-bottom: 48px;
`

const Header = () => {
  const location = useLocation()
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const title = data.site.siteMetadata?.title
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  if (isRootPath) {
    return (
      <MainContainer>
        <SiteTitle>
          <Link to='/'>{title}</Link>
        </SiteTitle>
      </MainContainer>
    )
  }

  return (
    <MainContainer>
      <ArticleTitle>
        <Link className='header-link-home' to='/'>
          {title}
        </Link>
      </ArticleTitle>
    </MainContainer>
  )
}

export default Header

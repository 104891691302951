import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { isEmpty, get, map } from 'lodash'

const List = styled.ol`
  list-style: none;
  margin-top: 16px;
  margin-bottom: 32px;

  & > li {
    margin-bottom: 16px;

    & > article {
      margin-top: 32px;
    }
  }
`

const TitleContainer = styled.header`
  margin-bottom: 16px;
`

const Title = styled.h2`
  margin-bottom: 8px;

  & > a {
    font-family: 'Montserrat';
    font-size: 27px;
    line-height: 30px;
    color: ${({ theme }) => get(theme, 'titleColor')};
    text-decoration: none;
    font-weight: 700;
    letter-spacing: -0.6912px;
  }
`

const Date = styled.small`
  font-family: 'Merriweather';
  font-size: 13px;
  color: ${({ theme }) => get(theme, 'textColor')};
  line-height: 19.2px;
`

const Description = styled.p`
  font-family: 'Merriweather';
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => get(theme, 'textColor')};
`

const PostItem = ({ post }) => {
  const title = post.frontmatter.title || post.fields.slug

  return (
    <li key={post.fields.slug}>
      <article
        className='post-list-item'
        itemScope
        itemType='http://schema.org/Article'>
        <TitleContainer>
          <Title>
            <Link to={post.fields.slug} itemProp='url'>
              <span itemProp='headline'>{title}</span>
            </Link>
          </Title>
          <Date>{post.frontmatter.date}</Date>
        </TitleContainer>
        <section>
          <Description
            dangerouslySetInnerHTML={{
              __html: post.frontmatter.description || post.excerpt,
            }}
            itemProp='description' />
        </section>
      </article>
    </li>
  )
}

const PostsList = ({ posts }) => {
  if (isEmpty(posts)) {
    return (
      <p>
        No blog posts found. Add markdown posts to 'content/blog' (or the
        directory you specified for the 'gatsby-source-filesystem' plugin in
        gatsby-config.js).
      </p>
    )
  }

  return (
    <List>
      {map(posts, post => (<PostItem post={post} />))}
    </List>
  )
}

export default PostsList
